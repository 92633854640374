<template>
  <div>
    <Datatable ref="datatable" route="/source/liste" :fields="fields" :data_filters="filters" titre="Liste des prospects" :sort="sort" :exportCSV="true">      
      <template #action>
        <b-button variant="success" class="mr-2 btn-icon" size="md" @click="tableToCSV()">
            <i class="fas fa-file-excel text-white fa-size"></i>
          </b-button>                        
      </template>
      <template #customField>
        <b-row>
          <b-col md="3" v-for="(p, i) in extraParam" :key="i">
            <b-form-group :label="i">
              <Input type="select-model" :options="p" v-model="filters[i]"/>
            </b-form-group>
          </b-col>          
        </b-row>
        <b-row>        
          <b-col md="3">
            <b-form-group label="Statut du lead">
              <b-form-group label=".">
                <Input type="select-model" :options="$api.params.ClientStatus" v-model="filters.client_status_id"/>
              </b-form-group>              
            </b-form-group>
          </b-col>          
          <b-col md="6">
            <b-form-group label="Date de creation">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Du">
                    <input type="date" class="form-control" v-model="creation_debut"/>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Au">
                    <input type="date" class="form-control" v-model="creation_fin"/>
                  </b-form-group>
                </b-col>
              </b-row>              
            </b-form-group>
          </b-col>                    
        </b-row>
      </template>       
      <template #cell(date_create)="data">
        {{$api.timestampToDate(data.value)}}
      </template>      
      <template #cell(date_update)="data">
        {{$api.timestampToDate(data.value)}}
      </template>   
      <template #cell(client_status_id)="data">
        <b-button :variant="status(data.value).color" size="sm">
          {{ status(data.value).titre }}
        </b-button>        
      </template>         
    </Datatable>    
  </div>
</template>
<script>
import Datatable from '@/components/Datatable';
import Input from '@/components/InputForm';
export default {
  name: "SourceListe",
  components: {
    Datatable, Input
  },
  watch:{    
  },
  data(){     
    return{
      filters:{
        min_date_create: null,
        max_date_create: null,    
        client_status_id:[]    
      },
      fields: [
        {
          key:"id",
          label:"ID",
          sortable:true,
        },
        {
          key:"nom",
          label:"Nom",
          sortable:false,
        },
        {
          key:"prenom",
          label:"Prenom",
          sortable:false,
        },
        {
          key:"email",
          label:"Email",
          sortable:false,
        },
        {
          key:"telephone_1",
          label:"Telephone",
          sortable:false,
        },
        {
          key:"date_naissance",
          label:"Date de naissance",
          sortable:false,
        },        
        {
          key:"cp",
          label:"CP",
          sortable:false,
        },        
        {
          key:"client_status_id",
          label:"Statut",
          sortable:false,
        },        
      ],      
      sort:{
        key: 'date_create',
        value: 'DESC'
      },  
      extraParam:[]                
    }
  },
  computed:{    
    creation_debut:{
      get(){
        var val = this.filters['min_date_create'];
        if(val === null){
          return null;
        }else{          
          var t = this.$api.moment(val * 1000).format("YYYY-MM-DD");          
          return t;
        }
      },
      set(v){
        if(v === null){
          this.filters['min_date_create'] = null
        }else{          
          this.filters['min_date_create'] = parseInt(this.$api.moment(v).unix())
        }
      }
    },
    creation_fin:{
      get(){
        var val = this.filters['max_date_create'];
        if(val === null){
          return null;
        }else{          
          return this.$api.moment(val * 1000).format("YYYY-MM-DD");
        }
      },
      set(v){
        if(v === null){
          this.filters['max_date_create'] = null
        }else{          
          this.filters['max_date_create'] = parseInt(this.$api.moment(v+" 23:59:00").unix())
        }
      }
    },
    modif_debut:{
      get(){
        var val = this.filters['min_date_update'];
        if(val === null){
          return null;
        }else{          
          return this.$api.moment(val * 1000).format("YYYY-MM-DD");
        }
      },
      set(v){
        if(v === null){
          this.filters['min_date_update'] = null
        }else{          
          this.filters['min_date_update'] = parseInt(this.$api.moment(v).unix())
        }
      }
    },
    modif_fin:{
      get(){
        var val = this.filters['max_date_update'];
        if(val === null){
          return null;
        }else{          
          return this.$api.moment(val * 1000).format("YYYY-MM-DD");
        }
      },
      set(v){
        if(v === null){
          this.filters['max_date_update'] = null
        }else{          
          this.filters['max_date_update'] = parseInt(this.$api.moment(v+" 23:59:00").unix())
        }
      }
    },
  },
  methods: {   
    status(status_id){
      if(status_id === ''){
        return {          
          titre:"Non definie",
          color:"dark"
        }
      }
      return this.$api.p('ClientStatus', status_id);      
    },
    tableToCSV(){
      var csv = [];
      var row = [];
      this.fields.forEach((e) => {        
        row.push(e.label);
      });
      csv.push(row.join(';'));   
      this.$refs.datatable.datatable.data.forEach((d) => {
        row = [];
        this.fields.forEach((f) => {
          var value = d[f.key];
          if(f.key === 'client_status_id'){            
            value = value === undefined ? '' : value;
            value = this.status(value).titre;
          }else if(value > 1602495218){
            value = this.$api.timestampToDate(value);
          }else if(value === undefined){
            value = null;
          }
          row.push(value)          
        })
        csv.push(row.join(';'));   
      })
      csv = csv.join("\n");
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Export.csv';
      hiddenElement.click();
    } 
  },
  beforeMount() {
    this.$api.ajax('/source/params', null, res => {
      if(res.status){
        this.fields = this.fields.concat(res.data.fields);     
        this.fields.push({
          key:"date_create",
          label:"Date creation",
          sortable:true
        });
        Object.keys(res.data.params).forEach((k) => {          
          this.filters[k] = [];
        })
        this.extraParam = res.data.params;
      }
    })
  },
  mounted() {        
  }
}
</script>
<style>
@media print {
  .menu-desktop, #menu-mobile, #header{
    display: none !important;
  }            
}
</style>
